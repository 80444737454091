@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/subset-Gotham-Light.woff2') format('woff2'),
  url('/assets/fonts/subset-Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/subset-Gotham-Black.woff2') format('woff2'),
  url('/assets/fonts/subset-Gotham-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'Gotham';
//  src: url('/assets/fonts/subset-Gotham-Medium.woff2') format('woff2'),
//  url('/assets/fonts/subset-Gotham-Medium.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Gotham MX';
  src: url('/assets/fonts/subset-GothamMX-CondensedRegular.woff2') format('woff2'),
  url('/assets/fonts/subset-GothamMX-CondensedRegular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
