.static-page {
  h1, h2, h3, h4, h5 {
    font-weight: bold;
    margin-top: 2rem;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 1.1;
   }

  h2 {
    font-size: 1.5rem;
    line-height: 1.1;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.25;
  }

  p {
    margin-top: 1rem;
  }
  ul {
    list-style: disc;
    margin-left: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;

    li {
      margin-block-start: 1rem;
    }
  }

  ol {
    list-style: decimal;
    margin-inline-start: 1rem;

    li {
      margin-block-start: 1rem;
    }
  }

  a {
    color: #5cc7fa;
  }

  table {
    overflow: auto;
    display: block;
    margin-top: 1rem;

    td, th {
      padding: 8px;
      border: 1px solid white;
    }
  }

}
