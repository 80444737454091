#mobile-disappear {
  @media (max-width: 450px) {
    display: none;
  }
}

ngx-material-timepicker-content {
  position: absolute;
  top: 20px;
}

.divider {
  //background-image: url("/assets/img/shared/divider.png");
  //background-position: center center;
  //background-size: cover;
  height: 1px;
  width: 100%;
  position: relative;
  padding-top: 25px;
  //background-color: var(--accent-color);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("/assets/img/shared/divider.png");
    background-position: center center;
    background-size: cover;
    height: 50px;
    //background: rgb(0, 0, 0, 0);
    //background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
  }

  @media (max-width: 575px) {
    background-size: cover;
  }

}

.divider-red {
  height: 2px;
  width: 100%;
  background-color: var(--main-color);
}

.divider-white {
  height: 2px;
  width: 100%;
  background-color: #fff;
}

.header-spacer {
  height: 50px;
}

.main-text-color {
  color: #fff;
}

.secondary-text-color {
  font-weight: bold;
}

.text-effect-white-bg {
  background-color: #fff;
  color: #000;
}

.title-placeholder {
  height: 1px;
  background-color: var(--main-color);
  width: 100%;
}

.title-wrapper {
  position: relative;
  margin-top: 35px;

  .title {
    font-weight: bold;
    display: inline-block;
    font-size: 53px;
    padding-top: 10px;

    &:before {
      content: "";
      width: 100%;
      clip-path: polygon(0 0, 98.5% 0, 100% 100%, 0% 100%);
      height: 50px;
      display: inline;
      background: var(--main-color);
      position: absolute;
      left: -100%;
      top: 32%;
    }

    &:after {
      //content: "";
      background-image: url("/assets/img/shared/bubbles.png");
      width: 140px;
      height: 110px;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      position: absolute;
      top: 0%;
      transform: translateX(-10px) translateY(-15px);

      @media (max-width: 768px) {
        width: 95px;
        height: 70px;
        transform: translateX(0px) translateY(-10px);
      }
    }

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 35px;
    }

    @media (max-width: 510px) {
      font-size: 26px;
      line-height: 30px;
    }

    &.green-bg {
      background-color: var(--main-color);

      &:before {
        background: white;
      }
    }
  }
}

//.band-yellow {
//  font-family: 'Bruzh', sans-serif;
//  background-image: url("/assets/img/shared/band-yellow.png");
//  background-position: center center;
//  background-size: 100% 100%;
//  padding: .9rem 2.5rem .5rem;
//
//  @media (max-width: 410px) {
//    padding: .6rem 2rem .2rem;
//  }
//
//  &.small {
//    padding: .2rem 1.5rem .1rem;
//
//  }
//}
//
//.band-blue {
//  font-family: 'Bruzh', sans-serif;
//  background-image: url("/assets/img/shared/band-blue.png");
//  background-position: center center;
//  background-size: 100% 100%;
//  padding: 1rem 2.5rem .5rem;
//
//  @media (max-width: 410px) {
//    padding: .6rem 2rem .2rem;
//  }
//
//  &.small {
//    padding: .2rem 1.5rem .1rem;
//
//  }
//}

.band-white {
  color: var(--main-color);
  background-color: #fff;
}

.band-red {
  color: #fff;
  background-color: var(--main-color);
}

.required {
  color: var(--accent-color);
  font-size: 12px;
}

.uppercase {
  text-transform: uppercase;
}

.hero-bg {
  position: relative;
  background-image: url("/assets/img/home/hero-bg-new.jpg");
  height: 650px;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .hero-img {
    position: absolute;
    bottom: 10%;
    right: 20%;
    width: 30%;
  }

  @media (max-width: 991px) {
    height: 485px;
  }


  @media (max-width: 768px) {
    height: 350px;

    .hero-img {
      width: 40%;
      bottom: 10%;
      right: 10%;
    }
  }

  @media (max-width: 450px) {
    height: unset;
    background-image: unset;

    .hero-img {
      position: relative;
      padding: 30px 20px 10px;
      width: 100%;
      bottom: 0;
      right: 0;
    }
  }
}

.faq-page-wrapper {
  padding-bottom: 40px;


  a {
    color: var(--secondary-color);
    text-decoration: none;

    //&:visited {
    //  color: red;
    //
    //}
  }

}

.packshot {
  max-width: 70%;
  margin: auto;
  padding-bottom: 100px;
  text-align: center;
  //padding-top: 40px;
  //transform: translateY(10px);
  //img {
  //  width: 100%;
  //}

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

}
